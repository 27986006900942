import { parseNumber } from 'libphonenumber-js'
import AvatarUploader from '@/components/Shared/AvatarUploader/AvatarUploader.vue'
import profileService from '@/services/queries/profileQueries.js'
import PhoneInput from '@/components/Shared/PhoneInput.vue'

export default {
  components: {
    AvatarUploader,
    PhoneInput
  },

  data() {
    return {
      submitting: false,
      formData: {
        full_name: '',
        nick_name: '',
        phone_number: '',
        phone_number_country_code: '',
        email: '',
        education: '',
        description: '',
        web_site: '',
        instagram_profile: '',
        country_id: null,
        timezone_id: null,
      },
      userCountry: null,
      userTimeZone: null,
      phoneNumberValidationRules: {
        valid_phone: null,
        phonenumber_unique: null
      },

      timeZones: [],
      timeZonesLoading: false,
    }
  },

  metaInfo() {
    return {
      title: this.$t('profileSettings.general.pageTitle')
    }
  },

  created() {
    this.timeZones = this.$store.state.handbooks.timeZones
    this.setFormData()
  },

  methods: {
    setFormData() {
      let formFields = [
        'full_name',
        'nick_name',
        'phone_number',
        'phone_number_country_code',
        'email',
        'education',
        'description',
        'web_site',
        'instagram_profile',
        'country_id',
        'timezone_id'
      ]

      this.formData = _.pick(this.$currentUser, formFields)

      if (this.formData.phone_number) {
        let phoneNumberCountryCode = parseNumber(this.formData.phone_number).country

        this.setPhoneNumberCountryCode(phoneNumberCountryCode)
      }

      this.setCountry()
      this.setTimeZone()
    },

    update() {
      this.submitting = true
      let refs = ['fullName', 'nickname', 'phoneNumber']

      if (this.$currentUser.isAgent) {
        refs = [...refs, 'instagramAccount', 'webSite']
      }

      let promises = []
      refs.forEach((ref) => {
        promises.push(this.$refs[ref].validate())
      })

      Promise.all(promises).then((results) => {
        let notValid = results.some((success) => {
          return !success
        })

        if (notValid) {
          this.submitting = false
          return
        }

        this.formData.country_id = this.userCountry ? this.userCountry.id: null
        this.formData.timezone_id = this.userTimeZone ? this.userTimeZone.id: null

        profileService.update(this.$currentUser.user_id, this.formData).then(response => {
          this.updateCurrentUser().then(() => {
            this.submitting = false
          })
        })
      })
    },

    setPhoneNumberCountryCode(countryCode) {
      this.phoneNumberValidationRules = {
        valid_phone: countryCode,
        phonenumber_unique: countryCode,
      }

      this.formData.phone_number_country_code = countryCode
    },

    setCountry() {
      if (!this.$currentUser.country_id) {
        return
      }

      this.userCountry = this.getCountryById(this.$currentUser.country_id)
    },

    setTimeZone() {
      if (!this.$currentUser.timezone_id) {
        return
      }

      this.userTimeZone = this.getTimeZoneById(this.$currentUser.timezone_id)
    },

    searchTimeZones: _.debounce(function(query) {
      this.timeZonesLoading = true

      this.findTimeZones(query).then(timeZones => {
        this.timeZones = query ? timeZones : this.fullTimeZoneList
        this.timeZonesLoading = false
      })
    }, 500),

    ...mapActions('auth', [
      'updateCurrentUser'
    ]),

    ...mapActions('timeZones', [
      'findTimeZones'
    ])
  },

  computed: {
    ...mapGetters('handbooks', [
      'getCountryById',
      'getTimeZoneById'
    ])
  }
}
